import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  NgbModal,
  NgbModalModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { People, PeopleDisplay, PeopleStatus } from '../../../../models/people';
import { PeopleService } from '../../../../services/people.service';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { PeopleEditComponent } from '../people-edit/people-edit.component';
import { GlobalService } from '../../../../services/global.service';

@Component({
  selector: 'app-people-view',
  standalone: true,
  imports: [
    CheckboxComponent,
    CommonModule,
    FormsModule,
    NgbModalModule,
    NgbPaginationModule,
    PeopleEditComponent,
    RouterModule,
  ],
  templateUrl: './people-view.component.html',
  styleUrl: './people-view.component.scss',
})
export class PeopleViewComponent implements OnInit {
  @ViewChild('adminEditPerson', { static: false }) private adminEditPerson: any;

  public people: Array<People> = [];
  public peopleFiltered: Array<People> = [];
  public peopleDisplay: Array<PeopleDisplay> = [];
  public personSelected: People;
  public page = 1;
  public pageSize = 10;
  public personSearch: string;
  public toggleNew = false;
  public toggleStatusChange = false;
  public type: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private peopleService: PeopleService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.globalService.adminTab = 4;
    this.setQueryParams();
    this.personSearch = '';
    this.getPeople();
  }

  getPeople() {
    this.peopleService
      .getPeopleSearch('<all>', 9999, -1)
      .subscribe((result) => {
        this.people = result;
        this.peopleFiltered = this.people;
        this.refreshPeople();
      });
  }

  refreshPeople() {
    const startIdx = (this.page - 1) * this.pageSize;
    const endIdx =
      startIdx + this.pageSize > this.peopleFiltered.length
        ? this.peopleFiltered.length
        : startIdx + this.pageSize;
    this.peopleDisplay = [];
    for (let i = startIdx; i < endIdx; i++) {
      const person = this.peopleFiltered[i];
      const personDisplay = new PeopleDisplay(
        person.id,
        person.name,
        this.peopleService.calcAge(person.dateOfBirth, person.dateOfDeath),
        person.knownAs,
        person.dateOfBirth,
        person.dateOfDeath,
        person.causeOfDeath,
        person.wikiURL,
        person.search,
        person.status,
        person.statusChangeRequestedBy
      );
      this.peopleDisplay.push(personDisplay);
    }
  }

  onSort(e: any) {
    console.log(e);
  }

  filterData() {
    this.peopleFiltered = this.people.filter(
      (p) =>
        p.search.toLowerCase().includes(this.personSearch.toLowerCase()) ||
        p.name.toLowerCase().includes(this.personSearch.toLowerCase())
    );
    // this.peopleFiltered = this.peopleFiltered.filter((p) =>
    //   this.toggleNew
    //     ? new Date(p.dateOfBirth).toLocaleDateString() ===
    //       new Date(1970, 1, 1).toLocaleDateString()
    //     : p
    // );
    this.peopleFiltered = this.peopleFiltered.filter((p) =>
      this.toggleNew ? p.status === PeopleStatus.NEW : p
    );
    this.peopleFiltered = this.peopleFiltered.filter((p) =>
      this.toggleStatusChange
        ? p.status === PeopleStatus.PENDING ||
          p.status === PeopleStatus.PENDING_DEAD
        : p
    );
    this.refreshPeople();
  }

  toggleOption(option: number) {
    switch (option) {
      case 1:
        this.toggleNew = !this.toggleNew;
        break;
      case 2:
        this.toggleStatusChange = !this.toggleStatusChange;
        break;
    }
  }

  setToggle(option: number, e: any) {
    switch (option) {
      case 1:
        this.toggleNew = e;
        this.filterData();
        break;
      case 2:
        this.toggleStatusChange = e;
        this.filterData();
        break;
    }
  }

  editPerson(id: number) {
    const _personSelected = this.people.find((p) => p.id === id);
    this.personSelected = _personSelected
      ? _personSelected
      : this.peopleService.defaultPeople;
    this.type = 'edit';
    this.modalService.open(this.adminEditPerson, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }

  returnOption(e: any) {
    if (e === 'cancel') {
      this.modalService.dismissAll();
    }
  }

  updatePeople(e: People) {
    this.modalService.dismissAll();
    const peopleIdx = this.people.findIndex((p) => p.id === e.id);
    // if -1 then this was an add
    if (peopleIdx === -1) {
      this.people.push(e);
    } else {
      this.people[peopleIdx] = e;
    }
    this.filterData();
    this.refreshPeople();
  }

  addPerson() {
    this.personSelected = this.peopleService.defaultPeople;
    this.type = 'new';
    this.modalService.open(this.adminEditPerson, {
      centered: true,
      size: 'lg',
      windowClass: 'dark-modal',
    });
  }

  setQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.globalService.adminQueryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}
