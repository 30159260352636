import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Contestant } from '../../../../models/contestant';
import { EntriesDisplay } from '../../../../models/entries';
import { ContestantService } from '../../../../services/contestant.service';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-ghoul-pool-contestant-lookup',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModalModule, RouterModule],
  templateUrl: './ghoul-pool-contestant-lookup.component.html',
  styleUrl: './ghoul-pool-contestant-lookup.component.scss',
})
export class GhoulPoolContestantLookupComponent implements OnInit {
  @Input()
  competitionId: number = 0;

  @Output()
  contestant: EventEmitter<Contestant> = new EventEmitter();

  @ViewChild('newContestant', { static: false }) private newContestantModal =
    null;

  public lookupSearching = false;
  public isEmailEntered = false;
  public contestantSave = false;
  public localContestant = new Contestant(0, '', '', '');
  public entries: Array<EntriesDisplay> = [];

  constructor(
    private contestantService: ContestantService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.isEmailEntered = false;
  }

  onEmailChange(e: any) {
    let validationCount = 0;
    if (this.localContestant.email.indexOf('@') >= 0) {
      validationCount += 1;
    }
    if (this.localContestant.email.indexOf('.') >= 0) {
      validationCount += 1;
    }
    if (validationCount === 2) {
      this.isEmailEntered = true;
    } else {
      this.isEmailEntered = false;
    }
  }

  clickEmail() {
    this.lookupSearching = true;
    this.contestantService
      .getContestantByEmail(this.localContestant.email)
      .subscribe({
        next: (results) => {
          this.lookupSearching = false;
          this.contestant.emit(results);
        },
        error: (error) => {
          this.lookupSearching = false;
          this.modalService.open(this.newContestantModal, {
            centered: true,
            size: 'lg',
            windowClass: 'dark-modal',
          });
          if (error.status === 404) {
            this.modalService.open(this.newContestantModal, {
              centered: true,
              size: 'lg',
              windowClass: 'dark-modal',
            });
          }
        },
      });
  }

  newContestantSubmit() {
    // if we aren't already starting to save
    if (!this.contestantSave) {
      this.contestantSave = true;
      this.contestantService.save(this.localContestant).subscribe((ret) => {
        this.contestantSave = false;
        this.localContestant.id = ret.id;
        this.contestant.emit(this.localContestant);
        this.modalService.dismissAll();
      });
    }
  }

  disableNewContestantSubmit() {
    return (
      this.localContestant.name === '' ||
      this.localContestant.email === '' ||
      this.localContestant.telephone === ''
    );
  }
}
