<div *ngIf="competition" class="container gm-border rounded">
  <div class="row">
    <div class="col-12 text-center">
      <h1>{{ competition.name }}</h1>
    </div>
  </div>
  <div *ngIf="allowCompetitionEntry()" class="row">
    <div *ngIf="showLookup" class="col-12">
      Thank you for your interest in our Ghoul Pool competition. If you have
      previously used the Ghoul Pool system then your email address should
      already be registered. Please enter your email address and click "Lookup".
      If you are not found, then you will also be asked to enter your name and
      cellphone. Once we have a registered email you can start making your
      entries. Use the people search box to find your celebrities. You can
      search using up to 5 words, so if you're looking for Dwayne The Rock
      Johnson, you can type it in in full or simply try The Rock. If you don't
      find the celebrity your looking for, then click the Add New button and
      enter in as much information as possible so that we can add it to the
      database.
    </div>
    <div *ngIf="!showLookup" class="col-12">
      Before the competition has officially started you can continue to access
      this page and make changes. Once the competition has officially started on
      {{ competition.entryEndDate | date }} you can continue to return to this
      page to see if any of your celebrities have died, and what points you have
      been awarded. If you know of a celebrity death that we may have missed,
      please click the "Dead?" button. This will send a message to us and we'll
      verify the information.
    </div>
  </div>
  <div *ngIf="!allowCompetitionEntry() && showLookup" class="row">
    <div class="col-12">
      This years Ghoul Pool ({{ competition.name }}) has closed, entries have to
      be in before {{ competition.entryEndDate | date }}. If you did make your
      entries and want to see what you entered, and your current score, enter
      the email address you used and select Lookup. If you want to lookup the
      current leader board click
      <a href="../ghoulpoolResults?code={{ competition.code }}">HERE</a>.
    </div>
  </div>
  <div *ngIf="!allowCompetitionEntry() && !showLookup" class="row">
    <div class="col-12">
      This years Ghoul Pool ({{ competition.name }}) has closed, entries had to
      be in before {{ competition.entryEndDate | date }}. Any entries you did
      make will be shown below along with any points or prizes you might have
      been awarded. Final scoring will not be added until the end of the year.
      If you want to bookmark this page click on your email address below and
      book mark that page. If you want to lookup the current leader board click
      <a href="../ghoulpoolResults?code={{ competition.code }}">HERE</a>.
    </div>
  </div>
  <app-ghoul-pool-contestant-lookup
    *ngIf="showLookup"
    [competitionId]="competition.id"
    (contestant)="returnContestantInfo($event)"
  ></app-ghoul-pool-contestant-lookup>
  <app-ghoul-pool-view-contestant-info
    *ngIf="!showLookup"
    [competition]="competition"
    [contestant]="localContestant"
    [entryCounts]="calcEntryCounts()"
  ></app-ghoul-pool-view-contestant-info>
  <app-ghoul-pool-people-lookup
    *ngIf="!showLookup && allowCompetitionEntry() && entries.length < 14"
    (person)="addPerson($event)"
  ></app-ghoul-pool-people-lookup>
  <app-ghoul-pool-view-entries
    *ngIf="!showLookup"
    [competition]="competition"
    [contestant]="localContestant"
    [entries]="entries"
    [totalPoints]="totalPoints"
    [totalPrizes]="totalPrizes"
    (deleteIdx)="deleteEntry($event)"
    (statusChange)="statusChange($event)"
  ></app-ghoul-pool-view-entries>
  <app-ghoul-pool-email-entries
    *ngIf="entries.length >= 14"
    [competition]="competition"
    [contestant]="localContestant"
    [entries]="entries"
  ></app-ghoul-pool-email-entries>
</div>
<div *ngIf="!competition" class="container gm-border rounded">
  <div class="row">
    <div class="col-12">
      <br />
      The Ghoul Pool service is currently down. Please check back later. If you
      continue to have issues please reach out to
      <a href="mailto:ghoulpool@ghoulishmortals.com"
        >ghoulpool&#64;ghoulishmortals.com</a
      >. <br />&nbsp;<br />
    </div>
  </div>
</div>
<ng-template #duplicateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Duplicate Entry</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('closed')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        The entry you made already exists in your list of entries. We are
        looking for
        {{ competition.maxEntries }} regular entries and
        {{ competition.maxAlternates }} alternate.
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-default cancel"
          (click)="duplicateOK()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</ng-template>
